@tailwind base;
@tailwind components;
@tailwind utilities;

@media only screen and (min-width: 1024px) {
    body {
        zoom: 120%;
        margin-bottom: 10px;
        margin-left: 10px;
        margin-right: 10px;
    }
    .chart {
        zoom: 83%;
    }
}

@media only screen and (min-width: 600px) and (max-width: 799px) {
    body {
        zoom: 110%;
        margin-bottom: 10px;
        margin-left: 10px;
        margin-right: 10px;
    }
    .chart {
        zoom: 90%;
    }
}

html {
    font-family: "Inter";
    scroll-behavior: smooth;
    height: 100%;
}

body {
    height: 100%;
    -webkit-touch-callout: none;
    /* -webkit-user-select: none;
    user-select: none; */
    -webkit-tap-highlight-color: transparent;
}

#root {
    height: 100%;
}

.hg-text-blue {
    color: #004BC3;
}

.hg-bg-blue {
    background: #004BC3;
}

.hg-bg-light-blue {
    background: #F4F9FF;
}


.hg-bg-blue {
    background: #004BC3;
}

.hg-border-b-blue {
    border-bottom: 2px solid #004BC3;
}

.hg-border-blue {
    border: 1px solid #004BC3;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.slider {
    /* background: rgb(31 41 55 / 900); */
    /* background: red; */
}

.slider::-webkit-slider-thumb {
    /* background: rgb(31 41 55 / 900); */
    background: red;
}

.slider::-webkit-slider-runnable-track {
    background: rgb(31 41 55 / 100);
    border-radius: 2em;
    /* background: blue; */
}

.curve {
    border: solid 10px red;
    width: 80%;
    height: 250px;
    border-radius: 50%;
    left: 55px;
    border-right: none;
    border-top: none;
    border-bottom: none;
    position: absolute;
    top: 100px;
}
